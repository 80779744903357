import React from 'react';
import Layout from '@components/layout';
import SEO from '@components/seo';

export default function NotFoundPage() {
  return (
    <Layout page="/404">
      <SEO title="404: Not found" />
      <div
        className="inner"
        style={{ padding: `5rem 3rem`, textAlign: `center` }}
      >
        <h1>NOT FOUND</h1>
        <h2 style={{ marginTop: `1.5rem` }}>Uh oh. Something went wrong.</h2>
      </div>
    </Layout>
  );
}
